import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import MachineMenu from './MachineMenu';
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CsvDownloader from 'react-csv-downloader';


function MachineTransactions() {
  let params = useParams();
  
  const [machineName, setMachineName] = useState("");
  const machineId = params.machineId;
  const [open, setOpen] = useState(false);

  const [transactions, setTransactions] = useState({key:"jan"});
  const [allProducts, setAllProducts] = useState({});
  const [allTransactions, setAllTransactions] = useState({});
  const [machine, setMachine] = useState(null);
  
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);

  function fetchMachineData() {

        
    getDoc(doc(getFirestore(), "machines", machineId)).then(
        (docSnap) => {
            let machine = docSnap.data();
            
          setMachine(docSnap.data());
        }
      );



   
    
      
  }

  function getAllDocs () {
    if (loading) {
      setLoading(true);
      getDocs(query(collection(getFirestore(), `machines/${machineId}/transactions`), orderBy("created_at", "desc"), limit(3))).then(
        (querySnapshot) => {
          let transactions = {};
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            transactions[doc.id] = "test";
          });
        }
      );
      console.log(transactions);
      //setAllTransactions(transactions);
      //setLoading(false);
      //done(true); // Proceed and get data from dataFromListOfUsersState function
      return transactions;
   
  }
}

const exportData = ( start,  end) => {
  // let end = new Date();
  // let start = new Date();
  // start.setDate(end.getDate()-50);
  console.log(start);
  console.log(end);
  
  let separator = ';';
  getProducts();
  let scvstring = "order_id;date;time;order_price;order_status;errorReason;product;product_price;product_state;product_tray;product_channel;product_error\r\n";
  
    getDocs(query(collection(getFirestore(), `machines/${machineId}/transactions`),where('created_at', '>', start)
    ,where('created_at', '<', end),orderBy('created_at', 'asc'))).then(
      (querySnapshot) => {
  
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          let mainOrderString = doc.id;
          mainOrderString += separator;
          
          mainOrderString += (new Date(data.created_at.seconds*1000).toLocaleString()).replace(",",";"); 
          mainOrderString += separator;
          mainOrderString += !isNaN(data.price)? (data.price.toFixed(2).toLocaleString()).replace('.',','):'0,0';
          mainOrderString += separator;
          mainOrderString += data.status;
          mainOrderString += separator;
          mainOrderString += (data.errorReason != undefined? data.errorReason:"");
          mainOrderString += separator;

          
          if(data.products[0])
          {
            scvstring += mainOrderString;
            scvstring += allProducts[data.products[0]?.id]?.name;
            scvstring += separator;
            scvstring += (data.products[0]?.price?.toFixed(2).toLocaleString().replace('.',','));
            scvstring += separator;
            scvstring += data.products[0]?.state;
            scvstring += separator;
            scvstring += data.products[0]?.tray?.toString();
            scvstring += separator;
            scvstring += data.products[0]?.channel?.toString();
            scvstring += separator;
            scvstring += (data.products[0]?.error_status != undefined && data.products[0]?.error_status != null?data.products[0]?.error_status:"");
            scvstring += separator;
            scvstring += "\r\n";
          }
          mainOrderString = doc.id;
          mainOrderString += separator;
          mainOrderString += (new Date(data.created_at.seconds*1000).toLocaleString()).replace(",",";"); 
          mainOrderString += separator;
          mainOrderString += '0,0'; // no price as it is part of the same order...
          mainOrderString += separator;
          mainOrderString += data.status;
          mainOrderString += separator;
          mainOrderString += (data.errorReason != undefined? data.errorReason:"");
          mainOrderString += separator;
          if(data.products[1])
            {
              scvstring += mainOrderString;
              scvstring += allProducts[data.products[1]?.id]?.name;
              scvstring += separator;
              
              scvstring += (data.products[1]?.price?.toFixed(2).toLocaleString().replace('.',','));
              
              scvstring += separator;
              scvstring += data.products[1]?.state;
              scvstring += separator;
              scvstring += data.products[1]?.tray?.toString();
            scvstring += separator;
            scvstring += data.products[1]?.channel?.toString();
              
              scvstring += separator;
            scvstring += (data.products[1]?.error_status != undefined && data.products[1]?.error_status != null?data.products[1]?.error_status:"");
              scvstring += "\r\n";
            }

            if(data.products[2])
              {
                scvstring += mainOrderString;
                scvstring += allProducts[data.products[2]?.id]?.name;
                scvstring += separator;
                scvstring += (data.products[2]?.price?.toFixed(2).toLocaleString().replace('.',','));
                scvstring += data.products[2]?.state;
                
                scvstring += separator;
                scvstring += data.products[2]?.tray?.toString();
            scvstring += separator;
            scvstring += data.products[2]?.channel?.toString();
            scvstring += separator;
              scvstring += (data.products[2]?.error_status != undefined && data.products[2]?.error_status != null?data.products[2]?.error_status:"");
                scvstring += separator;
                
                scvstring += "\r\n";
              }


        });
        
        console.log(scvstring);
        const csvString = `data:text/csv;chatset=utf-8,${encodeURIComponent(
          scvstring
        )}`;
        const link = document.createElement("a");
        link.href = csvString;
        link.download = "data.csv";
        
        link.download = machineName + '-'+start.getFullYear().toString() + '-'+start.toLocaleString('default', { month: 'long' }) +".csv" ;
        
        link.click();
      }
    );
}

  


// dataFromAllTransactionsState = () => {
//   return this.state.allTransactions;
// }

// render() {
//   const {loading} = this.state;
//   return <CSVLink
//     data={this.dataFromListOfUsersState}
//     asyncOnClick={true}
//     onClick={this.getUsers}
//   >
//     {loading ? 'Loading csv...' : 'Download me'}
//   </CSVLink>;

 function getProducts(){
  getDocs(collection(getFirestore(), 'products')).then((querySnapshot) => {
    let products = {};
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      products[doc.id] = data;
      setAllProducts(products);
    });
  });
}


useEffect( () => {
  let end = new Date();
let start = new Date();
start.setDate(end.getDate()-50);
console.log(start);
console.log(end);

getProducts();
fetchMachineData();
  getDocs(query(collection(getFirestore(), `machines/${machineId}/transactions`),where('created_at', '>', start)
  ,where('created_at', '<', end),orderBy('created_at', 'asc'))).then(
    (querySnapshot) => {
      let transactions = [];
      

      querySnapshot.forEach((doc) => {
        let transaction = {};
        let data = doc.data();
        data.id = doc.id;
        transaction.id = doc.id;
        transaction.created_at = new Date(data.created_at.seconds*1000).toLocaleString(); 
        transaction.price = data.price;
        transaction.status = data.status;
        transaction.product1 = allProducts[data.products[0]?.id]?.name;
        transaction.product1_location = "" + data.products[0]?.tray + " - " + data.products[0]?.channel;
        transaction.product2 = allProducts[data.products[1]?.id]?.name;
        transaction.product2_location = "" + data.products[1]?.tray + " - " + data.products[1]?.channel;
        transaction.product3 = allProducts[data.products[2]?.id]?.name;
        transaction.product3_location = "" + data.products[2]?.tray + " - " + data.products[2]?.channel;
        //transactions[doc.id] = transaction;
        transactions.push(transaction);
      });
      setTransactions(transactions);
      console.log(transactions);
    }
  );

  const fetchData = async () => {

    const docRef = doc(getFirestore(), "machines", machineId);
    const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  let data = docSnap.data();
  console.log("Document data:", data);
  setMachineName( data.name + '-' + data.location );
  
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}


    // let document = await getDoc();
    
  
    // let data = document.data();
    // console.log(data);
    // _machineName = data.name + '-' + data.location ;
  }

  fetchData();
    

  
}, [machineId]);
// useEffect(() => {
//   getDocs(
//     query(collection(getFirestore(), `machines/${machineId}/transactions`),orderBy("created_at", "desc"),limit(3))
//   ).then(async (querySnapshot) => {
//     let transactions = {};

//     console.log(querySnapshot.docs);

//     // Map each product to a promise that resolves to the name
//     const namePromises = querySnapshot.docs.map(async (doc) => {
//       let data = doc.data();
//       data.id = doc.id;
//       transactions[doc.id] = data;


//       if (data.products) {
//         for(let product of data.products){
//           const name = await getDoc(product.reference)
//           .then((doc) => {
//             product.name = doc.data().name;
//           })
//           .catch((error) => {
//             console.log(error);
//             return null;
//           });
//         return name;
//         }

//       }
//     });

//     // Wait for all promises to resolve
//     const resolvedNames = await Promise.all(namePromises);

//     // Update the state with the resolved names
//     setTransactions(transactions);
//   });
// }, [machineId]);



return (
  <div>
    {/* <Button
      style={{ marginBottom: "20px" }}
      onClick={getAllDocs()}
      variant="contained"
    >
      Add product
    </Button> */}
    {/* <CSVLink
    data={transactions}
    asyncOnClick={true}
    onClick={getAllDocs()}
  >
    {loading ? 'Loading csv...' : 'Download me'}
  </CSVLink>; */}
      
      {MachineMenu(machine, machineId)}
      <br />
      <h1>2024
      </h1>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('March 1, 2024 00:00:00'),new Date('April 1, 2024 00:00:00'))}
        variant="contained"
      >
        Export March
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('April 1, 2024 00:00:00'), new Date('May 1, 2024 00:00:00'))}
        variant="contained"
      >
        Export April
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('May 1, 2024 00:00:00'),new Date('June 1, 2024 00:00:00'))}
        variant="contained"
      >
        Export May
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('June 1, 2024 00:00:00'),new Date('July 1, 2024 00:00:00'))}
        variant="contained"
      >
        Export June
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('July 1, 2024 00:00:00'),new Date('August 1, 2024 00:00:00'))}
        variant="contained"
      >
        Export July
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('August 1, 2024 00:00:00'),new Date('September 1, 2024 00:00:00'))}
        variant="contained"
      >
        Export August
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('September 1, 2024 00:00:00'),new Date('October 1, 2024 00:00:00'))}
        variant="contained"
      >
        Export September
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('October 1, 2024 00:00:00'),new Date('November 1, 2024 00:00:00'))}
        variant="contained"
      >
        Export October
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('November 1, 2024 00:00:00'),new Date('December 1, 2024 00:00:00'))}
        variant="contained"
      >
        Export November
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('December 1, 2024 00:00:00'),new Date('January 1, 2025 00:00:00'))}
        variant="contained"
      >
        Export December
      </Button>
      <br />
      <h1>2025
      </h1>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('January 1, 2025 00:00:00'),new Date('February 1, 2025 00:00:00'))}
        variant="contained"
      >
        Export January
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('February 1, 2025 00:00:00'),new Date('March 1, 2025 00:00:00'))}
        variant="contained"
      >
        Export February
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('March 1, 2025 00:00:00'),new Date('April 1, 2025 00:00:00'))}
        variant="contained"
      >
        Export March
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('April 1, 2025 00:00:00'),new Date('May 1, 2025 00:00:00'))}
        variant="contained"
      >
        Export April
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('May 1, 2025 00:00:00'),new Date('June 1, 2025 00:00:00'))}
        variant="contained"
      >
        Export May
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('June 1, 2025 00:00:00'),new Date('July 1, 2025 00:00:00'))}
        variant="contained"
      >
        Export June
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('July 1, 2025 00:00:00'),new Date('August 1, 2025 00:00:00'))}
        variant="contained"
      >
        Export July
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('August 1, 2025 00:00:00'),new Date('September 1, 2025 00:00:00'))}
        variant="contained"
      >
        Export August
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('September 1, 2025 00:00:00'),new Date('October 1, 2025 00:00:00'))}
        variant="contained"
      >
        Export September
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('October 1, 2025 00:00:00'),new Date('November 1, 2025 00:00:00'))}
        variant="contained"
      >
        Export October
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('November 1, 2025 00:00:00'),new Date('December 1, 2025 00:00:00'))}
        variant="contained"
      >
        Export November
      </Button>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => exportData(new Date('December 1, 2025 00:00:00'),new Date('January 1, 2026 00:00:00'))}
        variant="contained"
      >
        Export December
      </Button>
      {/* <button type="button" onClick={exportData(new Date('March 1, 2024 00:00:00'),new Date('April 1, 2024 00:00:00'))}>
        Export March
      </button> */}
      {/* <button type="button" onClick={exportData(new Date('April 1, 2024 00:00:00'), new Date('May 1, 2024 00:00:00'))}>
        Export April
      </button>
      <button type="button" onClick={exportData(new Date('May 1, 2024 00:00:00'), new Date('June 1, 2024 00:00:00'))}>
        Export May
      </button>
      <button type="button" onClick={exportData(new Date('June 1, 2024 00:00:00'), new Date('July 1, 2024 00:00:00'))}>
        Export June
      </button>
      <button type="button" onClick={exportData(new Date('July 1, 2024 00:00:00'), new Date('August 1, 2024 00:00:00'))}>
        Export July
      </button>
      <button type="button" onClick={exportData(new Date('August 1, 2024 00:00:00'), new Date('September 1, 2024 00:00:00'))}>
        Export August
      </button> */}
    
    <Outlet />

  </div>
);
}

export default MachineTransactions;
